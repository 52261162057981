<template>
    <div>
        <p id="introTxt">
            Hello, 
            <br>
            I am a full stack developer with a couple of production experiences. I write code detail oriented, well documented and tested or as fast as greased lightning. I currently seeking to take next career step with a growing startup.
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>